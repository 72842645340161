<template>
  <v-card tile>
    <v-card-text class="text-center">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>Aprobar envío multiparada</h2>
            <!-- {{ item.total }}
            <br />
            {{ wallet }}
            <br />
            {{ parseFloat(this.item.total) > wallet }} -->
          </v-col>
        </v-row>
        <template v-if="step === 1">
          <v-row dense>
            <v-col cols="12">
              <p class="">¿Desea aprobar el siguiente envío multiparada?</p>
            </v-col>
          </v-row>
          <!-- tabla del envio -->
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :disable-sort="!false"
                :items="[item]"
                class="dataTable"
                :hide-default-footer="true"
                show-expand
                single-expand
                item-key="unique_id"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row class="d-flex">
                      <v-col cols="12" md="12">
                        <v-card class="pa-4 mt-2 mb-2">
                          <div style="border-bottom: solid 1px #ef4370">
                            <span
                              class="
                                d-flex
                                flex-row
                                justify-sm-space-between
                                align-center
                              "
                            >
                              <h2>Multi Paradas</h2>
                              <v-icon>{{ icons.mdiMapMarkerPath }}</v-icon>
                            </span>
                            <br />
                          </div>
                          <v-list>
                            <v-list-item-content
                              v-for="(stop, index) in item.routes"
                              :key="stop.index"
                            >
                              <span v-if="index === 0" class="mb-6">
                                <v-list-item-title
                                  class="d-flex flex-row align-center"
                                >
                                  <v-img
                                    :src="
                                      require(`@/views/ParcelMultistop/assets/Stop${index}.png`)
                                    "
                                    max-width="42"
                                  ></v-img>
                                  {{
                                    stop.source_address
                                      .split(",")
                                      .splice(0, 2)
                                      .join(",")
                                  }}
                                </v-list-item-title>
                              </span>
                              <v-list-item-title
                                class="d-flex flex-row align-center"
                              >
                                <v-img
                                  :src="
                                    require(`@/views/ParcelMultistop/assets/Stop${
                                      index + 1
                                    }.png`)
                                  "
                                  max-width="42"
                                ></v-img>
                                {{
                                  stop.destination_address
                                    .split(",")
                                    .splice(0, 2)
                                    .join(",")
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="
                                  ml-13
                                  d-flex
                                  flex-row
                                  justify-start
                                  align-center
                                "
                              >
                                <span>
                                  Total parada:
                                  {{ stop.estimated_fare | currency }}
                                </span>
                                <!-- <span class="ml-1"> | </span>
                                <span class="ml-1">
                                  Tiempo estimado:
                                  {{ Math.ceil(stop.time) }}m
                                </span>
                                <span class="ml-1"> | </span>
                                <span class="ml-1">
                                  Distancia estimada:
                                  {{ stop.distance }}Km
                                </span> -->
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </td>
                </template>

                <template #item.stop_quantity="{ item }">
                  <span>
                    <td>
                      <v-img
                        :src="
                          require(`@/views/ParcelMultistop/assets/Stop${item.routes.length}.png`)
                        "
                      ></v-img>
                    </td>
                  </span>
                </template>
                <template #item.quote_status="{ value }">
                  <span v-if="value === 0">
                    <td>
                      <v-chip
                        class="v-chip-light-bg warning--text"
                        color="warning"
                      >
                        Cotizado
                      </v-chip>
                    </td>
                  </span>
                </template>
                <template #item.total="{ value }">
                  <span>
                    <td>
                      {{ value | currency }}
                    </td>
                  </span>
                </template>

                <template #item.start_time="{ item }">
                  <span>
                    <td>
                      {{ item.start_time | moment("DD-MM-YYYY h:mm a") }}
                    </td>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-flex flex-row justify-end">
              <v-btn color="primary" outlined @click="handleCancelModal()">
                Salir
              </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex flex-row justify-start">
              <v-btn color="primary" @click="step++"> Siguiente </v-btn>
            </v-col>
          </v-row>
        </template>
        <!-- Escoger usuario responsable -->
        <template v-if="step === 2">
          <v-row dense>
            <v-col cols="12">
              <p class="">Escoja al usuario responsable del envío</p>
            </v-col>
          </v-row>
          <br />
          <ValidationObserver v-slot="{ invalid }">
            <v-form
              spellcheck="false"
              class="d-flex flex-column justify-space-between"
            >
              <!-- :style="$vuetify.breakpoint.mdAndUp && 'min-height: 44rem'" -->
              <v-row class="d-flex flex-row justify-center">
                <v-col cols="6">
                  <span v-if="approveMultiStopForm.userType === 'Corporativo'">
                    <ValidationProvider
                      name="Nombre del usuario"
                      rules="required"
                      v-slot="{ errors }"
                      v-if="approveMultiStopForm.userType === 'Corporativo'"
                      slim
                    >
                      <v-autocomplete
                        v-if="approveMultiStopForm.userType === 'Corporativo'"
                        v-model="approveMultiStopForm.userID"
                        label="Nombre del usuario"
                        :items="approveMultiStopForm.corporateUsersSearch"
                        outlined
                        :error-messages="errors[0] && ['Campo obligatorio']"
                        item-value="_id"
                        :menu-props="{ contentClass: 'list-style' }"
                        :filter="handleFilterAutocompleteCorporateUsers"
                        dense
                        @click="approveMultiStopForm.userID = null"
                      >
                        <template v-slot:selection="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <!-- color="primary"
                                small
                                outlined -->
                              <v-avatar color="success" size="25" class="mr-1">
                                <span
                                  class="d-flex align-center justify-center"
                                >
                                  <v-img
                                    :src="`${bucketUrl}${item.picture}`"
                                    :alt="item.initials"
                                    width="25"
                                    v-if="item.picture"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="grey lighten-5"
                                        ></v-progress-circular>
                                      </v-row> </template
                                  ></v-img>
                                  <span
                                    v-else
                                    class="color-blue-text text-caption"
                                  >
                                    {{ item.initials }}
                                  </span>
                                </span>
                              </v-avatar>
                              <span v-bind="attrs" v-on="on">
                                <span class="">
                                  {{ item.fullName }}
                                </span>
                              </span>
                            </template>
                            <span class="d-flex flex-column">
                              <span class="d-flex flex-row align-center">
                                <span class="ml-1">
                                  {{ item.phone }}
                                </span>
                                <v-icon
                                  x-small
                                  class="ml-1"
                                  style="color: #ffffff !important"
                                >
                                  {{ icons.mdiPhoneOutline }}
                                </v-icon>
                                <span class="ml-1">|</span>
                                <span class="ml-1">
                                  {{ item.email }}
                                </span>
                                <v-icon
                                  x-small
                                  class="ml-1"
                                  style="color: #ffffff !important"
                                >
                                  {{ icons.mdiEmailOutline }}
                                </v-icon>
                              </span>
                              <span class="mt-1">
                                <span class="ml-1">
                                  <span class="font-weight-bold">
                                    {{ "Saldo" }}:
                                  </span>
                                  {{ item.wallet | currency }}
                                </span>
                                <span class="ml-1">|</span>
                                <span class="ml-1">
                                  <span class="font-weight-bold">
                                    {{ "Sobregiro" }}:
                                  </span>
                                  {{ item.overdraft ? "Sí" : "No" }}
                                </span>
                              </span>
                            </span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar color="#3BD4AE" size="45">
                            <span v-if="item.picture">
                              <v-img
                                :src="`${bucketUrl}${item.picture}`"
                                :alt="item.initials"
                                width="45"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row> </template
                              ></v-img>
                            </span>
                            <span v-else>
                              {{ item.initials }}
                            </span>
                          </v-list-item-avatar>
                          <br />
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.fullName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="d-flex flex-column">
                                <span>
                                  <span>
                                    {{ item.phone }}
                                  </span>
                                  <v-icon x-small class="ml-1">
                                    {{ icons.mdiPhoneOutline }}
                                  </v-icon>
                                  <span class="ml-1">|</span>
                                  <span class="ml-1">
                                    {{ item.email }}
                                  </span>
                                  <v-icon x-small class="ml-1">
                                    {{ icons.mdiEmailOutline }}
                                  </v-icon>
                                </span>
                                <span class="mt-1">
                                  <span class="ml-1">
                                    <span class="font-weight-bold">
                                      {{ "Saldo" }}:
                                    </span>
                                    {{ item.wallet | currency }}
                                  </span>
                                  <span class="ml-1">|</span>
                                  <span class="ml-1">
                                    <span class="font-weight-bold">
                                      {{ "Sobregiro" }}:
                                    </span>
                                    {{ item.overdraft ? "Sí" : "No" }}
                                  </span>
                                </span>
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <template v-slot:append-outer>
                          <div>
                            <v-tooltip top :max-width="200">
                              <template #activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">
                                  {{ icons.mdiInformationOutline }}
                                </v-icon>
                              </template>
                              <span>
                                {{ "Este campo es autocompletado" }}
                                <br />
                                {{ "Puedes buscar usuario corporativos por:" }}
                                <ul class="mt-2">
                                  <li>
                                    {{ "Nombre completo" }}
                                  </li>
                                  <li>
                                    {{ "Correo electrónico" }}
                                  </li>
                                  <li>
                                    {{ "Telefóno" }}
                                  </li>
                                </ul>
                              </span>
                            </v-tooltip>
                          </div>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </span>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  approveMultiStopForm.userType === 'Corporativo' &&
                  approveMultiStopForm.userID
                "
              >
                <v-col
                  cols="12"
                  class="d-flex flex-row justify-center align-center"
                >
                  <v-switch v-model="approveMultiStopForm.isCorporateWallet">
                    <template v-slot:label>
                      <span class="text-caption">
                        <span v-if="!approveMultiStopForm.isCorporateWallet">
                          Usar <u>saldo del usuario</u> para el viaje
                        </span>
                        <span v-else>
                          Usar <u>balance corporativo</u> para el viaje
                        </span>
                        <span>
                          <v-tooltip top :max-width="300">
                            <template #activator="{ on, attrs }">
                              <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="color-gray-text"
                                :style="handleColorClose"
                              >
                                {{ icons.mdiInformationOutline }}
                              </v-icon>
                            </template>
                            <span v-if="approveMultiStopForm.isCorporateWallet">
                              Al momento de crear el viaje para el usuario
                              corporativo
                              <b>{{ computedCurrentCorporateUser.fullName }}</b>
                              , el costo del mismo será debitado del
                              <u>balance corporativo</u> ({{
                                wallet | currency
                              }}), no del <u>saldo</u> ({{
                                computedCurrentCorporateUser.wallet | currency
                              }})
                              <span
                                v-if="computedCurrentCorporateUser.overdraft"
                              >
                                ni <u>sobregiro</u>
                              </span>
                              de
                              <b>{{ computedCurrentCorporateUser.fullName }}</b>
                            </span>
                            <span v-else>
                              Al momento de crear el viaje para el usuario
                              corporativo
                              <b>{{ computedCurrentCorporateUser.fullName }}</b
                              >, el costo del mismo será debitado de su
                              <u>saldo</u> ({{
                                computedCurrentCorporateUser.wallet | currency
                              }})
                              <span
                                v-if="computedCurrentCorporateUser.overdraft"
                              >
                                o de su <u>sobregiro</u>
                              </span>
                            </span>
                          </v-tooltip>
                        </span>
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  approveMultiStopForm.userType === 'Corporativo' &&
                  approveMultiStopForm.userID
                "
                class="color-red-text"
              >
                <v-col cols="12">
                  <span
                    class="font-weight-bold"
                    v-if="computedDisabledCreateTripNote"
                    >Nota:
                  </span>
                  <span
                    v-if="computedDisabledCreateTripNote"
                    v-html="computedDisabledCreateTripNote"
                  >
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="d-flex flex-row justify-end">
                  <v-btn color="primary" outlined @click="step--">
                    Regresar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex flex-row justify-start">
                  <v-btn
                    color="primary"
                    :disabled="invalid || computedDisabledCreateTrip"
                    @click="step++"
                  >
                    Siguiente
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </template>
        <!-- Detalles de cada parada  -->
        <template v-if="step === 3">
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12">
                <p class="">Definir detalles de cada parada</p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-row class="d-flex">
                  <v-col cols="12" md="12">
                    <div style="border-bottom: solid 1px #ef4370">
                      <span
                        class="
                          d-flex
                          flex-row
                          justify-sm-space-between
                          align-center
                        "
                      >
                        <h2>Multi Paradas</h2>
                        <v-icon>{{ icons.mdiMapMarkerPath }}</v-icon>
                      </span>
                      <br />
                    </div>
                    <v-list>
                      <v-list-item-content
                        v-for="(stop, index) in item.routes"
                        :key="stop.index"
                      >
                        <span v-if="index === 0" class="mb-6">
                          <v-list-item-title
                            class="ml-0 mb-4 d-flex flex-row align-center"
                          >
                            <v-img
                              :src="
                                require(`@/views/ParcelMultistop/assets/Stop${index}.png`)
                              "
                              max-width="42"
                            ></v-img>
                            {{
                              stop.source_address
                                .split(",")
                                .splice(0, 2)
                                .join(",")
                            }}
                          </v-list-item-title>
                        </span>
                        <span
                          :style="
                            index % 2 === 0 &&
                            (!$vuetify.theme.isDark
                              ? 'background-color: #FAFAFA;'
                              : 'background-color: #0F1221;')
                          "
                        >
                          <v-list-item-title
                            class="d-flex flex-row align-center"
                          >
                            <v-img
                              :src="
                                require(`@/views/ParcelMultistop/assets/Stop${
                                  index + 1
                                }.png`)
                              "
                              max-width="42"
                            ></v-img>
                            {{
                              stop.destination_address
                                .split(",")
                                .splice(0, 2)
                                .join(",")
                            }}
                            <v-list-item-subtitle
                              class="
                                ml-13
                                d-flex
                                flex-row
                                justify-start
                                align-center
                              "
                            >
                              <span>
                                Total parada:
                                {{ stop.estimated_fare | currency }}
                              </span>
                              <!-- <span class="ml-1"> | </span>
                                  <span class="ml-1">
                                    Tiempo estimado:
                                    {{ Math.ceil(stop.time) }}m
                                  </span>
                                  <span class="ml-1"> | </span>
                                  <span class="ml-1">
                                    Distancia estimada:
                                    {{ stop.distance }}Km
                                  </span> -->
                              <span class="ml-1"> | </span>
                              <span class="ml-1">
                                Detalles de parada de envío:
                                <v-icon style="color: #ffb400 !important">
                                  {{ icons.mdiAlertOutline }}
                                </v-icon>
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-title>
                          <div class="mt-4">
                            <v-row dense>
                              <v-col cols="12">
                                <p>Detalles de paquete y quién recibe</p>
                              </v-col>

                              <v-col md="6" cols="12">
                                <ValidationProvider
                                  name="Nombre"
                                  rules="required|min:2|alpha_spaces"
                                  v-slot="{ errors }"
                                  slim
                                >
                                  <!-- autocomplete="new-password" para evitar sugerencias de chrome -->
                                  <v-text-field
                                    v-model="
                                      approveMultiStopForm.details[index]
                                        .responsible_first_name
                                    "
                                    label="Nombre"
                                    outlined
                                    dense
                                    autocomplete="new-password"
                                    :error-messages="
                                      errors[0] && 'Campo obligatorio'
                                    "
                                  ></v-text-field>
                                </ValidationProvider>
                              </v-col>
                              <v-col md="6" cols="12">
                                <ValidationProvider
                                  name="Apellido"
                                  rules="required|min:2|alpha_spaces"
                                  v-slot="{ errors }"
                                  slim
                                >
                                  <v-text-field
                                    v-model="
                                      approveMultiStopForm.details[index]
                                        .responsible_last_name
                                    "
                                    label="Apellido"
                                    outlined
                                    dense
                                    autocomplete="new-password"
                                    :error-messages="
                                      errors[0] && 'Campo obligatorio'
                                    "
                                  ></v-text-field>
                                </ValidationProvider>
                              </v-col>
                              <v-col md="6" cols="12">
                                <v-row dense>
                                  <v-col cols="5">
                                    <ValidationProvider
                                      name="Operadora"
                                      rules="required"
                                      v-slot="{ errors }"
                                      slim
                                    >
                                      <v-select
                                        :items="
                                          approveMultiStopForm.phonePrefixList
                                        "
                                        v-model="
                                          approveMultiStopForm.details[index]
                                            .responsible_phone_prefix
                                        "
                                        label="Operadora"
                                        outlined
                                        dense
                                        :error-messages="
                                          errors[0] && 'Campo obligatorio'
                                        "
                                      ></v-select>
                                    </ValidationProvider>
                                  </v-col>
                                  <v-col cols="7">
                                    <ValidationProvider
                                      name="Teléfono"
                                      rules="required|min:7|max:7"
                                      v-slot="{ errors }"
                                      slim
                                    >
                                      <PhoneInput
                                        label="Teléfono"
                                        v-model="
                                          approveMultiStopForm.details[index]
                                            .responsible_phone
                                        "
                                        dense
                                        outlined
                                        :long="false"
                                        :error-messages="
                                          errors[0] && 'Campo obligatorio'
                                        "
                                      />
                                    </ValidationProvider>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-row>
                                  <v-col cols="12">
                                    <ValidationProvider
                                      name="Detalles del envío"
                                      rules="required|min:2"
                                      v-slot="{ errors }"
                                      slim
                                    >
                                      <v-textarea
                                        dense
                                        v-model="
                                          approveMultiStopForm.details[index]
                                            .package_description
                                        "
                                        height="35"
                                        outlined
                                        clearable
                                        :rules="[
                                          (v) =>
                                            (v || '').length <= 150 ||
                                            `Excediste la capacidad de caracteres`,
                                        ]"
                                        label="Detalles del envío"
                                        placeholder=" Ej. Objeto Frágil / Caja de chocolates/ Agenda."
                                        :error-messages="
                                          errors[0] && 'Campo obligatorio'
                                        "
                                      ></v-textarea>
                                    </ValidationProvider>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                        </span>
                      </v-list-item-content>
                    </v-list>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox v-model="paquete.checkbox">
                      <template v-slot:label>
                        <div class="text-caption">
                          <span v-if="$vuetify.lang.current === 'es'">
                            He leído y estoy de acuerdo con las
                            <a class="text-decoration-underline" href="/parcel-guide" target="_blank" @click.stop>
                              Políticas</a
                            >
                            de envíos de
                            <span class="font-weight-bold fontUrbanist text-lg"
                              >Ridery</span
                            >
                          </span>
                          <span v-else>
                            I have read and I agree to the
                            <a class="text-decoration-underline" href="/parcel-guide" target="_blank" @click.stop>
                              Policies
                            </a>
                            related to parcels from
                            <span class="font-weight-bold fontUrbanist text-lg"
                              >Ridery</span
                            >
                          </span>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="d-flex flex-row justify-end">
                    <v-btn color="primary" outlined @click="step--">
                      Regresar
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="d-flex flex-row justify-start">
                    <v-tooltip bottom :disabled="!invalid">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn
                            color="primary"
                            @click="handleApproveMultiStop()"
                            :disabled="invalid || !paquete.checkbox"
                          >
                            Aprobar
                          </v-btn>
                        </span>
                      </template>
                      <span
                        >Debes definir cada detalle en la parada de envío</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </ValidationObserver>
        </template>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMapMarkerPath } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import {
  mdiWindowClose,
  mdiMapMarker,
  mdiCalendar,
  mdiClock,
  mdiPhoneOutline,
  mdiEmailOutline,
  mdiAccount,
  mdiBagSuitcase,
  mdiRefresh,
  mdiInformationOutline,
  mdiAlertOutline,
} from "@mdi/js";
import PhoneInput from "@/components/PhoneInput";

export default {
  components: {
    PhoneInput,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiMapMarkerPath,
        mdiWindowClose,
        mdiMapMarker,
        mdiCalendar,
        mdiClock,
        mdiPhoneOutline,
        mdiEmailOutline,
        mdiAccount,
        mdiBagSuitcase,
        mdiRefresh,
        mdiInformationOutline,
        mdiAlertOutline,
      },
      headers: [
        {
          text: "ID",
          value: "unique_id",
          align: "start",
          sortable: false,
        },
        {
          text: "FECHA Y HORA",
          value: "start_time",
          align: "start",
          sortable: false,
        },
        { text: "COSTO", value: "total", align: "end", sortable: false },
        {
          text: "DIRECCION DE ORIGEN",
          value: "source_address",
          align: "start",
          sortable: false,
        },
        {
          text: "ULTIMA PARADA",
          value: "destination_address",
          align: "start",
          sortable: false,
        },
        {
          text: "PARADAS",
          value: "stop_quantity",
          align: "end",
          sortable: false,
        },
        {
          text: "STATUS",
          value: "quote_status",
          align: "center",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
      approveMultiStopForm: {
        userType: "Corporativo",
        userTypeOptions: ["Corporativo", "No corporativo"],
        corporateUsersSearch: [],
        userID: null,
        isCorporateWallet: false,
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: null,
        phone: null,
        userFirstName: null,
        userLastName: null,
        email: null,
        details: [],
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      overdraftUser: null,
      corporateWalletUser: null,
      loadingUserSearch: false,
      corporateUsersSearch: [],
      notCorporateDisabled: true,
      notCorporateDisabledEmail: true,
      step: 1,
      paquete: {
        checkbox: false
      }
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["meNotLoading"]),

    async getCorporateUsersSearch() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/all_users`
        );
        if (data) {
          this.corporateUsersSearch = data;
        }
      } catch (error) {
        this.$dialog.notify.error("Error getting corporate users search");
      }
    },

    handleCancelModal() {
      this.$emit("submit", null);
    },
    async getCorporateUsers() {
      this.setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/users`
        );
        if (!!data) {
          this.approveMultiStopForm.corporateUsersSearch = [];
          data.user_list.forEach((user) => {
            Boolean(user.is_approved) &&
              this.approveMultiStopForm.corporateUsersSearch.push({
                fullName: `${user.first_name} ${user.last_name}`,
                _id: user._id,
                phone: user.phone,
                initials: user.first_name[0] + user.last_name[0],
                email: user.email,
                overdraft: user.is_allow_overdraft,
                wallet: user.corporate_wallet_limit,
                picture: user.picture,
              });
          });
          this.approveMultiStopForm.corporateUsersSearch.sort((a, b) => {
            return a.fullName.localeCompare(b.fullName);
          });
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    handleFilterAutocompleteCorporateUsers(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const fullName = _item.fullName.toLowerCase();
      const phone = _item.phone.toLowerCase();
      const email = _item.email.toLowerCase();

      return (
        fullName.indexOf(searchText) > -1 ||
        phone.indexOf(searchText) > -1 ||
        email.indexOf(searchText) > -1
      );
    },
    handleUserStatus(user, corporate_id) {
      if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "0"
      ) {
        return "Active";
      } else if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "1"
      ) {
        return "Waiting for approval";
      } else if (
        user.old_corporate_ids.toString().includes(corporate_id.toString())
      ) {
        return "Inactive";
      }
    },
    async handleApproveMultiStop() {
      this.setLoading(true);
      try {
        let params = {
          quote_status: 1,
          quote_status_reason: "",
          user_id: this.approveMultiStopForm.userID,
          is_corporate_wallet:
            this.approveMultiStopForm.userType === "Corporativo" &&
            this.approveMultiStopForm.isCorporateWallet,
          shipments: [],
        };
        this.approveMultiStopForm.details.forEach((element) => {
          params.shipments.push({
            responsible_first_name: element.responsible_first_name,
            responsible_last_name: element.responsible_last_name,
            responsible_phone:
              element.responsible_phone_prefix.slice(1) +
              element.responsible_phone,
            package_type: "",
            package_description: element.package_description,
            package_weight: "",
          });
        });
        const { data } = await axios.put(
          `${process.env.VUE_APP_API_URL}/quotes/${this.item._id}/update_status`,
          params
        );
        if (!!data) {
          this.$dialog.notify.success(
            "Cotización de multiparada aprobada con éxito"
          );
          this.$emit("submit", true);
        } else {
          throw new Error("Error in /quotes/id/update_status");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async handleCreate() {
      this.setLoading(true);

      try {
        if (this.approveMultiStopForm.userType === "Corporativo") {
          const check = await this.handleCheck(
            this.approveMultiStopForm.userID
          );
          if (check.success) {
            await this.handleApproveMultiStop();
          } else {
            throw new Error("Error en check user");
          }
        }
        if (this.approveMultiStopForm.userType === "No corporativo") {
          let check = await this.handleCheck();
          if (check.success) {
            this.approveMultiStopForm.userID = check.user._id;
            await this.handleApproveMultiStop();
          } else {
            throw new Error("Error en check user");
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async handleCheck(id) {
      //corporate
      this.setLoading(true);
      try {
        let params = {};
        if (id) {
          params["user_id"] = id;
        }
        if (!id) {
          params = {
            user_id: "",
            first_name: this.approveMultiStopForm.userFirstName,
            last_name: this.approveMultiStopForm.userLastName,
            email: this.approveMultiStopForm.email,
            is_new_user: 1,
            phone:
              this.approveMultiStopForm.phonePrefix.substring(1) +
              this.approveMultiStopForm.phone,
            country_phone_code: this.countryPhoneCode,
            city: "Caracas", //ajustar con respuesta de service type
            country: this.countryName,
          };
        }
        //validamos usuario
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/checkuser_corporate`,
          params
        );
        return data;
      } catch (error) {
        this.$dialog.notify.error("error check");
      }
      this.setLoading(false);
    },
    capitalizeFirstLetter: (str) => {
      return str ? str[0].toUpperCase() + str.slice(1) : "";
    },
  },
  computed: {
    ...mapState("auth", ["wallet"]),
    ...mapState("auth", [
      "countryName",
      "countryPhoneCode",
      "_id",
      "pre_time",
      "wallet",
      "unique_id",
    ]),
    computedCurrentCorporateUser() {
      return this.approveMultiStopForm.corporateUsersSearch.find(
        (user) => user._id === this.approveMultiStopForm.userID
      );
    },
    handleColorClose() {
      return this.$vuetify.theme.dark
        ? "color: #ffffff !important;"
        : "color: #3a3541 !important;";
    },
    computedDisabledCreateTrip() {
      if (true) {
        if (
          this.approveMultiStopForm.userType === "Corporativo" &&
          !this.approveMultiStopForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            !this.overdraftUser
          ) {
            return true;
          }
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            this.overdraftUser &&
            parseFloat(this.item.total) > this.wallet
          ) {
            return true;
          }
        } else {
          if (parseFloat(this.item.total) > this.wallet) {
            return true;
          }
        }
        return false;
      }
    },
    computedDisabledCreateTripNote() {
      if (true) {
        if (
          this.approveMultiStopForm.userType === "Corporativo" &&
          !this.approveMultiStopForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            !this.overdraftUser
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}) <span class="font-weight-bold text-decoration-underline">ni</span> posee sobregiro`;
          }
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            this.overdraftUser &&
            parseFloat(this.item.total) > this.wallet
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}), posee sobregiro, pero el panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el viaje ($${
              this.wallet
            })`;
          }
        } else {
          if (parseFloat(this.item.total) > this.wallet) {
            console.log("da");
            return (
              `El panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el viaje` +
              " " +
              `($${this.wallet})`
            );
          }
        }
        return false;
      }
    },
    computedPhoneNotCorporate() {
      return (
        this.approveMultiStopForm.phonePrefix &&
        this.approveMultiStopForm.phone &&
        this.approveMultiStopForm.phonePrefix.slice(1) +
          this.approveMultiStopForm.phone
      );
    },
  },
  created() {
    this.meNotLoading();
    this.getCorporateUsersSearch();
    this.getCorporateUsers();
    this.item.routes.forEach((element) => {
      this.approveMultiStopForm.details.push({
        responsible_first_name: "", //req
        responsible_last_name: "", //req
        responsible_phone: "", //req
        responsible_phone_prefix: "", //req
        package_type: "",
        package_description: "", //req
        package_weight: "",
      });
    });
  },
  watch: {
    "approveMultiStopForm.userID": function () {
      if (this.approveMultiStopForm.userType === "Corporativo") {
        this.overdraftUser =
          this.approveMultiStopForm.corporateUsersSearch.find(
            (u) => u._id === this.approveMultiStopForm.userID
          ).overdraft;
        this.corporateWalletUser =
          this.approveMultiStopForm.corporateUsersSearch.find(
            (u) => u._id === this.approveMultiStopForm.userID
          ).wallet;
      }
    },
    computedPhoneNotCorporate: async function (_newVal, _oldVal) {
      if (
        this.computedPhoneNotCorporate &&
        this.computedPhoneNotCorporate.length === 10
      ) {
        try {
          this.loadingUserSearch = true;
          let params = {
            phone:
              this.approveMultiStopForm.phonePrefix.slice(1) +
              this.approveMultiStopForm.phone,
          };
          const { data } = await axios.get(
            `${process.env.VUE_APP_API_URL}/get_user_details`,
            { params: params }
          );
          console.log(data);
          //si el usuario existe en la base de datos de Ridery
          if (data.success) {
            this.$dialog.notify.success(
              "Usuario encontrado en la base de datos de Ridery"
            );
            //Se verifica que el usuario no este en la nomina del panel
            let found = this.corporateUsersSearch.users.find((user) => {
              return (
                user.phone ===
                this.approveMultiStopForm.phonePrefix.slice(1) +
                  this.approveMultiStopForm.phone
              );
            });
            // Si existe en la nomina del corporativo
            if (found) {
              let status = this.handleUserStatus(
                found,
                this.corporateUsersSearch.corporate_id
              );
              //si esta activo se devuelve a corporate users en el formulario
              if (status === "Active") {
                this.$dialog.notify.warning(
                  `El usuario correspondiente al número de teléfono ${this.approveMultiStopForm.phonePrefix}-${this.approveMultiStopForm.phone} se encuentra activo en el panel, por favor crear viaje como corporativo`
                );
                this.approveMultiStopForm.userType = "Corporativo";
                this.approveMultiStopForm.phone = "";
                this.approveMultiStopForm.phonePrefix = "";
                this.approveMultiStopForm.userID = "";
                this.notCorporateDisabled = true;
              }
              if (status === "Waiting for approval") {
                this.$dialog.notify.warning(
                  `El usuario correspondiente al número de teléfono ${this.approveMultiStopForm.phonePrefix}-${this.approveMultiStopForm.phone} se encuentra esperando aprobación en el panel, por favor sugerir al usuario aceptar invitación al panel corporativo desde la app móvil de Ridery`
                );
                //Si existe en la nomina esperando apobacion, hay que proteger los campos para evitar duplicados, se devuelve a users
                this.notCorporateDisabled = true;
                // this.$router.push({
                //   name: "users",
                //   query: { waiting_approval_trip: 1 },
                // });
                window.location.href =
                  "/corporate_users?waiting_approval_trip=1";
              }
              if (status === "Inactive") {
                this.$dialog.notify.warning(
                  "Usuario se encuentra inactivo en el panel"
                );
                this.notCorporateDisabled = true;
                const { user } = data;
                this.approveMultiStopForm.userFirstName = user.first_name;
                this.approveMultiStopForm.userLastName = user.last_name;
                if (user.email) {
                  this.approveMultiStopForm.email = user.email;
                  this.notCorporateDisabledEmail = true;
                } else {
                  this.approveMultiStopForm.email = "";
                  this.notCorporateDisabledEmail = false;
                }
              }
            }
            //si no esta en la nomina, pero si esta en la base de datos, se llenan los campos con los datos del user y se inhabilita la edicion
            if (!found) {
              this.$dialog.notify.success(
                "Usuario encontrado en base de datos rellenando campos"
              );
              this.notCorporateDisabled = true;
              const { user } = data;
              this.approveMultiStopForm.userFirstName = user.first_name;
              this.approveMultiStopForm.userLastName = user.last_name;
              if (user.email) {
                this.approveMultiStopForm.email = user.email;
                this.notCorporateDisabledEmail = true;
              } else {
                this.approveMultiStopForm.email = "";
                this.notCorporateDisabledEmail = false;
              }
            }
          }
          //usuario no encontrado en la base de datos de ridery
          if (!data.success) {
            console.log("user not found");
            this.approveMultiStopForm.email = "";
            this.approveMultiStopForm.userFirstName = "";
            this.approveMultiStopForm.userLastName = "";
            this.notCorporateDisabled = false;
            this.notCorporateDisabledEmail = false;
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loadingUserSearch = false;
        }
      }
    },
    "approveMultiStopForm.userFirstName": function (newVal) {
      if (newVal) {
        this.approveMultiStopForm.userFirstName =
          this.capitalizeFirstLetter(newVal);
      }
    },
    "approveMultiStopForm.userLastName": function (newVal) {
      if (newVal) {
        this.approveMultiStopForm.userLastName =
          this.capitalizeFirstLetter(newVal);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.vuedl-layout__closeBtn
    // color: white
    // opacity: 1
    color: rgba(58, 53, 65, 0.54)
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px
</style>
