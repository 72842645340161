var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-container',{staticClass:"pa-12"},[_c('v-row',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Aprobar envío multiparada")])])],1),(_vm.step === 1)?[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{},[_vm._v("¿Desea aprobar el siguiente envío multiparada?")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"dataTable",attrs:{"headers":_vm.headers,"disable-sort":!false,"items":[_vm.item],"hide-default-footer":true,"show-expand":"","single-expand":"","item-key":"unique_id"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"pa-4 mt-2 mb-2"},[_c('div',{staticStyle:{"border-bottom":"solid 1px #ef4370"}},[_c('span',{staticClass:"\n                              d-flex\n                              flex-row\n                              justify-sm-space-between\n                              align-center\n                            "},[_c('h2',[_vm._v("Multi Paradas")]),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMapMarkerPath))])],1),_c('br')]),_c('v-list',_vm._l((item.routes),function(stop,index){return _c('v-list-item-content',{key:stop.index},[(index === 0)?_c('span',{staticClass:"mb-6"},[_c('v-list-item-title',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{attrs:{"src":require(("@/views/ParcelMultistop/assets/Stop" + index + ".png")),"max-width":"42"}}),_vm._v(" "+_vm._s(stop.source_address .split(",") .splice(0, 2) .join(","))+" ")],1)],1):_vm._e(),_c('v-list-item-title',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{attrs:{"src":require(("@/views/ParcelMultistop/assets/Stop" + (index + 1) + ".png")),"max-width":"42"}}),_vm._v(" "+_vm._s(stop.destination_address .split(",") .splice(0, 2) .join(","))+" ")],1),_c('v-list-item-subtitle',{staticClass:"\n                                ml-13\n                                d-flex\n                                flex-row\n                                justify-start\n                                align-center\n                              "},[_c('span',[_vm._v(" Total parada: "+_vm._s(_vm._f("currency")(stop.estimated_fare))+" ")])])],1)}),1)],1)],1)],1)],1)]}},{key:"item.stop_quantity",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_c('td',[_c('v-img',{attrs:{"src":require(("@/views/ParcelMultistop/assets/Stop" + (item.routes.length) + ".png"))}})],1)])]}},{key:"item.quote_status",fn:function(ref){
                                  var value = ref.value;
return [(value === 0)?_c('span',[_c('td',[_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning"}},[_vm._v(" Cotizado ")])],1)]):_vm._e()]}},{key:"item.total",fn:function(ref){
                                  var value = ref.value;
return [_c('span',[_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")])])]}},{key:"item.start_time",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.start_time,"DD-MM-YYYY h:mm a"))+" ")])])]}}],null,false,2903391826)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-end",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.handleCancelModal()}}},[_vm._v(" Salir ")])],1),_c('v-col',{staticClass:"d-flex flex-row justify-start",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step++}}},[_vm._v(" Siguiente ")])],1)],1)]:_vm._e(),(_vm.step === 2)?[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{},[_vm._v("Escoja al usuario responsable del envío")])])],1),_c('br'),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var invalid = ref.invalid;
return [_c('v-form',{staticClass:"d-flex flex-column justify-space-between",attrs:{"spellcheck":"false"}},[_c('v-row',{staticClass:"d-flex flex-row justify-center"},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.approveMultiStopForm.userType === 'Corporativo')?_c('span',[(_vm.approveMultiStopForm.userType === 'Corporativo')?_c('ValidationProvider',{attrs:{"name":"Nombre del usuario","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [(_vm.approveMultiStopForm.userType === 'Corporativo')?_c('v-autocomplete',{attrs:{"label":"Nombre del usuario","items":_vm.approveMultiStopForm.corporateUsersSearch,"outlined":"","error-messages":errors[0] && ['Campo obligatorio'],"item-value":"_id","menu-props":{ contentClass: 'list-style' },"filter":_vm.handleFilterAutocompleteCorporateUsers,"dense":""},on:{"click":function($event){_vm.approveMultiStopForm.userID = null}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
                                  var attrs = ref.attrs;
return [_c('v-avatar',{staticClass:"mr-1",attrs:{"color":"success","size":"25"}},[_c('span',{staticClass:"d-flex align-center justify-center"},[(item.picture)?_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (item.picture)),"alt":item.initials,"width":"25"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_c('span',{staticClass:"color-blue-text text-caption"},[_vm._v(" "+_vm._s(item.initials)+" ")])],1)]),_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',{},[_vm._v(" "+_vm._s(item.fullName)+" ")])])]}}],null,true)},[_c('span',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-flex flex-row align-center"},[_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.phone)+" ")]),_c('v-icon',{staticClass:"ml-1",staticStyle:{"color":"#ffffff !important"},attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPhoneOutline)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("|")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('v-icon',{staticClass:"ml-1",staticStyle:{"color":"#ffffff !important"},attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmailOutline)+" ")])],1),_c('span',{staticClass:"mt-1"},[_c('span',{staticClass:"ml-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s("Saldo")+": ")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.wallet))+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("|")]),_c('span',{staticClass:"ml-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s("Sobregiro")+": ")]),_vm._v(" "+_vm._s(item.overdraft ? "Sí" : "No")+" ")])])])])]}},{key:"item",fn:function(ref){
                                  var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"color":"#3BD4AE","size":"45"}},[(item.picture)?_c('span',[_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (item.picture)),"alt":item.initials,"width":"45"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1):_c('span',[_vm._v(" "+_vm._s(item.initials)+" ")])]),_c('br'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"d-flex flex-column"},[_c('span',[_c('span',[_vm._v(" "+_vm._s(item.phone)+" ")]),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPhoneOutline)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("|")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmailOutline)+" ")])],1),_c('span',{staticClass:"mt-1"},[_c('span',{staticClass:"ml-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s("Saldo")+": ")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.wallet))+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("|")]),_c('span',{staticClass:"ml-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s("Sobregiro")+": ")]),_vm._v(" "+_vm._s(item.overdraft ? "Sí" : "No")+" ")])])])])],1)]}},{key:"append-outer",fn:function(){return [_c('div',[_c('v-tooltip',{attrs:{"top":"","max-width":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
                                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s("Este campo es autocompletado")+" "),_c('br'),_vm._v(" "+_vm._s("Puedes buscar usuario corporativos por:")+" "),_c('ul',{staticClass:"mt-2"},[_c('li',[_vm._v(" "+_vm._s("Nombre completo")+" ")]),_c('li',[_vm._v(" "+_vm._s("Correo electrónico")+" ")]),_c('li',[_vm._v(" "+_vm._s("Telefóno")+" ")])])])])],1)]},proxy:true}],null,true),model:{value:(_vm.approveMultiStopForm.userID),callback:function ($$v) {_vm.$set(_vm.approveMultiStopForm, "userID", $$v)},expression:"approveMultiStopForm.userID"}}):_vm._e()]}}],null,true)}):_vm._e()],1):_vm._e()])],1),(
                _vm.approveMultiStopForm.userType === 'Corporativo' &&
                _vm.approveMultiStopForm.userID
              )?_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"cols":"12"}},[_c('v-switch',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-caption"},[(!_vm.approveMultiStopForm.isCorporateWallet)?_c('span',[_vm._v(" Usar "),_c('u',[_vm._v("saldo del usuario")]),_vm._v(" para el viaje ")]):_c('span',[_vm._v(" Usar "),_c('u',[_vm._v("balance corporativo")]),_vm._v(" para el viaje ")]),_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"color-gray-text",style:(_vm.handleColorClose),attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}],null,true)},[(_vm.approveMultiStopForm.isCorporateWallet)?_c('span',[_vm._v(" Al momento de crear el viaje para el usuario corporativo "),_c('b',[_vm._v(_vm._s(_vm.computedCurrentCorporateUser.fullName))]),_vm._v(" , el costo del mismo será debitado del "),_c('u',[_vm._v("balance corporativo")]),_vm._v(" ("+_vm._s(_vm._f("currency")(_vm.wallet))+"), no del "),_c('u',[_vm._v("saldo")]),_vm._v(" ("+_vm._s(_vm._f("currency")(_vm.computedCurrentCorporateUser.wallet))+") "),(_vm.computedCurrentCorporateUser.overdraft)?_c('span',[_vm._v(" ni "),_c('u',[_vm._v("sobregiro")])]):_vm._e(),_vm._v(" de "),_c('b',[_vm._v(_vm._s(_vm.computedCurrentCorporateUser.fullName))])]):_c('span',[_vm._v(" Al momento de crear el viaje para el usuario corporativo "),_c('b',[_vm._v(_vm._s(_vm.computedCurrentCorporateUser.fullName))]),_vm._v(", el costo del mismo será debitado de su "),_c('u',[_vm._v("saldo")]),_vm._v(" ("+_vm._s(_vm._f("currency")(_vm.computedCurrentCorporateUser.wallet))+") "),(_vm.computedCurrentCorporateUser.overdraft)?_c('span',[_vm._v(" o de su "),_c('u',[_vm._v("sobregiro")])]):_vm._e()])])],1)])]},proxy:true}],null,true),model:{value:(_vm.approveMultiStopForm.isCorporateWallet),callback:function ($$v) {_vm.$set(_vm.approveMultiStopForm, "isCorporateWallet", $$v)},expression:"approveMultiStopForm.isCorporateWallet"}})],1)],1):_vm._e(),(
                _vm.approveMultiStopForm.userType === 'Corporativo' &&
                _vm.approveMultiStopForm.userID
              )?_c('v-row',{staticClass:"color-red-text"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.computedDisabledCreateTripNote)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nota: ")]):_vm._e(),(_vm.computedDisabledCreateTripNote)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.computedDisabledCreateTripNote)}}):_vm._e()])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-end",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.step--}}},[_vm._v(" Regresar ")])],1),_c('v-col',{staticClass:"d-flex flex-row justify-start",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.computedDisabledCreateTrip},on:{"click":function($event){_vm.step++}}},[_vm._v(" Siguiente ")])],1)],1)],1)]}}],null,false,787163565)})]:_vm._e(),(_vm.step === 3)?[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{},[_vm._v("Definir detalles de cada parada")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticStyle:{"border-bottom":"solid 1px #ef4370"}},[_c('span',{staticClass:"\n                        d-flex\n                        flex-row\n                        justify-sm-space-between\n                        align-center\n                      "},[_c('h2',[_vm._v("Multi Paradas")]),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMapMarkerPath))])],1),_c('br')]),_c('v-list',_vm._l((_vm.item.routes),function(stop,index){return _c('v-list-item-content',{key:stop.index},[(index === 0)?_c('span',{staticClass:"mb-6"},[_c('v-list-item-title',{staticClass:"ml-0 mb-4 d-flex flex-row align-center"},[_c('v-img',{attrs:{"src":require(("@/views/ParcelMultistop/assets/Stop" + index + ".png")),"max-width":"42"}}),_vm._v(" "+_vm._s(stop.source_address .split(",") .splice(0, 2) .join(","))+" ")],1)],1):_vm._e(),_c('span',{style:(index % 2 === 0 &&
                          (!_vm.$vuetify.theme.isDark
                            ? 'background-color: #FAFAFA;'
                            : 'background-color: #0F1221;'))},[_c('v-list-item-title',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{attrs:{"src":require(("@/views/ParcelMultistop/assets/Stop" + (index + 1) + ".png")),"max-width":"42"}}),_vm._v(" "+_vm._s(stop.destination_address .split(",") .splice(0, 2) .join(","))+" "),_c('v-list-item-subtitle',{staticClass:"\n                              ml-13\n                              d-flex\n                              flex-row\n                              justify-start\n                              align-center\n                            "},[_c('span',[_vm._v(" Total parada: "+_vm._s(_vm._f("currency")(stop.estimated_fare))+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" | ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" Detalles de parada de envío: "),_c('v-icon',{staticStyle:{"color":"#ffb400 !important"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOutline)+" ")])],1)])],1),_c('div',{staticClass:"mt-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Detalles de paquete y quién recibe")])]),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required|min:2|alpha_spaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","outlined":"","dense":"","autocomplete":"new-password","error-messages":errors[0] && 'Campo obligatorio'},model:{value:(
                                    _vm.approveMultiStopForm.details[index]
                                      .responsible_first_name
                                  ),callback:function ($$v) {_vm.$set(_vm.approveMultiStopForm.details[index]
                                      , "responsible_first_name", $$v)},expression:"\n                                    approveMultiStopForm.details[index]\n                                      .responsible_first_name\n                                  "}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Apellido","rules":"required|min:2|alpha_spaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Apellido","outlined":"","dense":"","autocomplete":"new-password","error-messages":errors[0] && 'Campo obligatorio'},model:{value:(
                                    _vm.approveMultiStopForm.details[index]
                                      .responsible_last_name
                                  ),callback:function ($$v) {_vm.$set(_vm.approveMultiStopForm.details[index]
                                      , "responsible_last_name", $$v)},expression:"\n                                    approveMultiStopForm.details[index]\n                                      .responsible_last_name\n                                  "}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"Operadora","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.approveMultiStopForm.phonePrefixList,"label":"Operadora","outlined":"","dense":"","error-messages":errors[0] && 'Campo obligatorio'},model:{value:(
                                        _vm.approveMultiStopForm.details[index]
                                          .responsible_phone_prefix
                                      ),callback:function ($$v) {_vm.$set(_vm.approveMultiStopForm.details[index]
                                          , "responsible_phone_prefix", $$v)},expression:"\n                                        approveMultiStopForm.details[index]\n                                          .responsible_phone_prefix\n                                      "}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono","rules":"required|min:7|max:7","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('PhoneInput',{attrs:{"label":"Teléfono","dense":"","outlined":"","long":false,"error-messages":errors[0] && 'Campo obligatorio'},model:{value:(
                                        _vm.approveMultiStopForm.details[index]
                                          .responsible_phone
                                      ),callback:function ($$v) {_vm.$set(_vm.approveMultiStopForm.details[index]
                                          , "responsible_phone", $$v)},expression:"\n                                        approveMultiStopForm.details[index]\n                                          .responsible_phone\n                                      "}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Detalles del envío","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"dense":"","height":"35","outlined":"","clearable":"","rules":[
                                        function (v) { return (v || '').length <= 150 ||
                                          "Excediste la capacidad de caracteres"; } ],"label":"Detalles del envío","placeholder":" Ej. Objeto Frágil / Caja de chocolates/ Agenda.","error-messages":errors[0] && 'Campo obligatorio'},model:{value:(
                                        _vm.approveMultiStopForm.details[index]
                                          .package_description
                                      ),callback:function ($$v) {_vm.$set(_vm.approveMultiStopForm.details[index]
                                          , "package_description", $$v)},expression:"\n                                        approveMultiStopForm.details[index]\n                                          .package_description\n                                      "}})]}}],null,true)})],1)],1)],1)],1)],1)],1)])}),1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-caption"},[(_vm.$vuetify.lang.current === 'es')?_c('span',[_vm._v(" He leído y estoy de acuerdo con las "),_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"/parcel-guide","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Políticas")]),_vm._v(" de envíos de "),_c('span',{staticClass:"font-weight-bold fontUrbanist text-lg"},[_vm._v("Ridery")])]):_c('span',[_vm._v(" I have read and I agree to the "),_c('a',{staticClass:"text-decoration-underline",attrs:{"href":"/parcel-guide","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Policies ")]),_vm._v(" related to parcels from "),_c('span',{staticClass:"font-weight-bold fontUrbanist text-lg"},[_vm._v("Ridery")])])])]},proxy:true}],null,true),model:{value:(_vm.paquete.checkbox),callback:function ($$v) {_vm.$set(_vm.paquete, "checkbox", $$v)},expression:"paquete.checkbox"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-end",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.step--}}},[_vm._v(" Regresar ")])],1),_c('v-col',{staticClass:"d-flex flex-row justify-start",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!invalid},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                          var on = ref.on;
                                          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || !_vm.paquete.checkbox},on:{"click":function($event){return _vm.handleApproveMultiStop()}}},[_vm._v(" Aprobar ")])],1)]}}],null,true)},[_c('span',[_vm._v("Debes definir cada detalle en la parada de envío")])])],1)],1)],1)],1)]}}],null,false,2666754002)})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }