var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":true,"items":_vm.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","mobile-breakpoint":"0","dense":true},scopedSlots:_vm._u([{key:"header.stopOrigin",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('@/views/Parcel/assets/IconOriginMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.stopDestination",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require('@/views/Parcel/assets/IconDestinationMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.costTotal",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","disabled":_vm.showPage},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+_vm._s(!_vm.showPage ? " *" : ""))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('variable_tariffs')))])])]}},{key:"item.stopNumber",fn:function(ref){
var value = ref.value;
return [_c('span',[_c('td',[_c('v-img',{attrs:{"src":require(("@/views/ParcelMultistop/assets/Stop" + value + ".png")),"max-width":"42"}})],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","disabled":item.stopNumber + 1 !== _vm.stateMultiStop.length || _vm.loading},on:{"click":function($event){return _vm.handleRemove(item.stopNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete'))+" "+_vm._s(_vm.$t('multistop_stop'))+" # "+_vm._s(item.stopNumber))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }