<template>
  <div
    class="d-flex flex-column justify-center align-center"
    :style="computedHeight"
  >
    <GmapMap
      :center="origin"
      ref="mapRef"
      :zoom="15"
      :style="computedHeight"
      :clickable="true"
      :draggable="true"
      :zoomControl="true"
      :options="computedOptions"
      v-show="loadingMap"
    >
      <!-- Paradas -->
      <GmapMarker
        v-for="(stop, index) in stops"
        :key="stop.index"
        :ref="`marker${index}`"
        :position="stop"
        :animation="2"
        :icon="markerOptions(index)"
      />
      <!-- path -->
      <GmapPolyline :path="stops" :options="options" />

      <!-- provider -->
      <GmapCustomMarker
        :marker="{
          lat: trip.providerLocation[0],
          lng: trip.providerLocation[1],
        }"
        v-if="
          viewMap &&
          trip.providerLocation.length > 0 &&
          dataProp.status !== 'Completed'
        "
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <img
              :src="markerOptionsCarTest.url"
              alt=""
              :style="`transform: rotate(${trip.bearing}deg);`"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>
            <ul>
              <li>ID viaje: {{ trip.unique_id }}</li>
              <li v-if="trip.is_provider_status === 6">
                Usuario:
                {{ trip.user_first_name + " " + trip.user_last_name }}
              </li>
              <li>
                Conductor:
                {{ trip.provider_first_name + " " + trip.provider_last_name }}
              </li>
              <li>Status: {{ $t(dataProp.status) }}</li>
            </ul>
          </span>
        </v-tooltip>
      </GmapCustomMarker>

      <!-- polyline trayectoria -->
      <GmapPolyline
        v-if="viewMap && dataProp.status === 'Completed'"
        :path="computedPath"
        :options="optionsPath"
      />
    </GmapMap>
    <v-progress-circular
      v-if="!loadingMap"
      indeterminate
      :size="64"
      color="primary"
    />
  </div>
</template>
<script>
import originIcon from "@/views/Parcel/assets/IconOriginMapMap.png";

import destinationIcon from "@/views/RunningTrips/assets/IconDestinationMapMap.png";

import { gmapApi } from "vue2-google-maps";
import ellasxellas from "@/views/RunningTrips/assets/ELLASxELLAS.png";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import { mdiMapMarker, mdiAccount } from "@mdi/js";

import nightMap from "@/views/RunningTrips/assets/nightMap";

import Stop1 from "../../assets/FirstStop.png";
import Stop2 from "../../assets/Stop2.png";
import Stop3 from "../../assets/Stop3.png";
import Stop4 from "../../assets/Stop4.png";
import Stop5 from "../../assets/Stop5.png";
import Stop6 from "../../assets/Stop6.png";
import Stop7 from "../../assets/Stop7.png";
import Stop8 from "../../assets/Stop8.png";
import Stop9 from "../../assets/Stop9.png";
import Stop10 from "../../assets/Stop10.png";

export default {
  components: {
    GmapCustomMarker,
  },
  props: {
    origin: {
      type: Object,
    },
    destination: {
      type: Object,
    },
    stops: {
      type: Array,
    },
    trip: {
      type: Object,
    },
    viewMap: {
      type: Boolean,
      default: false,
    },
    dataProp: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiMapMarker,
        mdiAccount,
      },
      centerCabled: { lat: 10.5041952, lng: -66.8858767 },
      loadingMap: false,
      markerOptionsOrigin: {
        url: originIcon,
      },
      markerOptionsDestination: {
        url: destinationIcon,
      },
      options: {
        strokeColor: "#EF4370",
        strokeOpacity: 0,
        geodesic: true,
        strokeWeight: 10,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              strokeWeight: 5,
              scale: 2.5,
            },
            offset: 0,
            repeat: "20px",
          },
        ],
      },
      optionsPath: {
        strokeColor: "#FFB400",
        strokeOpacity: 1,
        geodesic: true,
        strokeWeight: 2,
        
        // icons: [
        //   {
        //     icon: {
        //       path: "M 0,-1 0,1",
        //       strokeOpacity: 1,
        //       strokeWeight: 5,
        //       scale: 2.5,
        //     },
        //     offset: 0,
        //     repeat: "20px",
        //   },
        // ],
      },
      markerOptionsCarTest: {
        url: ellasxellas,
      },
      markerOptionsStop1: {
        url: Stop1,
      },
      markerOptionsStop2: {
        url: Stop2,
      },
      markerOptionsStop3: {
        url: Stop3,
      },
      markerOptionsStop4: {
        url: Stop4,
      },
      markerOptionsStop5: {
        url: Stop5,
      },
      markerOptionsStop6: {
        url: Stop6,
      },
      markerOptionsStop7: {
        url: Stop7,
      },
      markerOptionsStop8: {
        url: Stop8,
      },
      markerOptionsStop9: {
        url: Stop9,
      },
      markerOptionsStop10: {
        url: Stop10,
      },
    };
  },
  methods: {
    markerOptions(index) {
      switch (index) {
        case 0:
          return this.markerOptionsOrigin;
        case 1:
          return this.markerOptionsStop1;
        case 2:
          return this.markerOptionsStop2;
        case 3:
          return this.markerOptionsStop3;
        case 4:
          return this.markerOptionsStop4;
        case 5:
          return this.markerOptionsStop5;
        case 6:
          return this.markerOptionsStop6;
        case 7:
          return this.markerOptionsStop7;
        case 8:
          return this.markerOptionsStop8;
        case 9:
          return this.markerOptionsStop9;
        case 10:
          return this.markerOptionsStop10;
        default:
          break;
      }
    },
  },
  computed: {
    google: gmapApi,
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return !this.formOpen
          ? "width: 100%; height: 500px"
          : "width: 100%; height: 700px";
      } else {
        return "width: 100%; height: 500px";
      }
    },
    computedOptions() {
      if (this.$vuetify.theme.isDark) {
        return {
          streetViewControl: false,
          styles: nightMap,
        };
      }
      if (!this.$vuetify.theme.isDark) {
        return { streetViewControl: false, styles: [] };
      }
    },
    computedPath() {
      let path = [];
      this.dataProp.tripLocation.startTripToEndTripLocations.forEach(
        (marker) => {
          path.push({ lat: marker[0], lng: marker[1] });
        }
      );
      return path;
    },
  },
  mounted() {
    if (this.$refs.mapRef) {
      this.$refs.mapRef.$mapPromise.then(() => {
        this.loadingMap = !this.loadingMap;
      });
    }
  },
  async created() {
    await new Promise((r) => setTimeout(r, 1200));
    let bounding = new this.google.maps.LatLngBounds();
    this.stops.forEach((stop) => {
      bounding.extend({
        lat: stop.lat,
        lng: stop.lng,
      });
    });
    this.$refs.mapRef.fitBounds(bounding);
  },
};
</script>
<style lang="scss" scoped></style>
