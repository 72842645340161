<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="true"
      :items="items"
      class="dataTable elevation-1"
      calculate-widths
      hide-default-footer
      disable-pagination
      fixed-header
      mobile-breakpoint="0"
      :dense="true"
    >
      <template v-slot:header.stopOrigin="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require('@/views/Parcel/assets/IconOriginMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>

      <template v-slot:header.stopDestination="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require('@/views/Parcel/assets/IconDestinationMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>

      <template v-slot:header.costTotal="{ header }">
        <v-tooltip top :disabled="showPage">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ header.text }}{{ !showPage ? " *" : "" }}</span
            >
          </template>
          <span>{{$t('variable_tariffs')}}</span>
        </v-tooltip>
      </template>

      <template #item.stopNumber="{ value }">
        <span>
          <td>
            <v-img
              :src="require(`@/views/ParcelMultistop/assets/Stop${value}.png`)"
              max-width="42"
            ></v-img>
          </td>
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              @click="handleRemove(item.stopNumber)"
              :disabled="item.stopNumber + 1 !== stateMultiStop.length || loading"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </template>
          <span>{{$t('Delete')}} {{$t('multistop_stop')}} # {{ item.stopNumber }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiRefresh,
  mdiMapMarker,
  mdiClose,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
    showPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMapMarker,
        mdiClose,
      },
    };
  },
  computed: {
    ...mapState(["stateMultiStop"]),

    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
  methods: {
    ...mapActions(["removeMultiStopStop", "setServiceTypeOrigin"]),
    handleRemove(index) {
      if (index > 1) {
        this.$dialog.notify.warning(
          `Se ha eliminado la parada #${index} - ${this.stateMultiStop[index].formatted_address}`,
          {
            position: "top-left",
          }
        );
        this.removeMultiStopStop();
      } else {
        this.$dialog.notify.warning(
          `Se ha eliminado la parada #${1} - ${
            this.stateMultiStop[1].formatted_address
          }`,
          {
            position: "top-left",
          }
        );
        this.$dialog.notify.warning(
          `Se ha eliminado la dirección de origen - ${this.stateMultiStop[0].formatted_address}`,
          {
            position: "top-left",
          }
        );
        while (this.stateMultiStop.length > 0) {
          this.removeMultiStopStop();
        }
        this.setServiceTypeOrigin(null);
      }
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
</style>
